export default class Constants {
    static FREMONT_PAGE_WIDTH_CLASS = "fremont-page-width";

    static SPINNER_ORANGE_COLOR ="#ec7211";

    static SEPARATOR = "___";

    static LINK_INSTANCE_ID_PATTERN = "amzn1.network.link.instance.";
    static NEST_BUG_ERROR_MESSAGE = "Please cut a bug to the NEST team to resolve this issue.";
    static CUTSHEET_HANDLE_STALE_LINKS_TOGGLE_MESSAGE = "Set this only in case if previous cutsheet upload failed " +
        "with stale links. If set, this will move all stale links to Deleted state.";
    static CUTSHEET_HANDLE_THIRD_PARTY_LINKS_TOGGLE_MESSAGE = "Set this only in case if cutsheet contains in_service" +
        "(operational) third party links.";

    static REPORT_FIBER_INFO = "Changes are not immediately reflected in the report.";

    static CONSUMPTION_ATTRIBUTES = {
        consumesList: "consumes",
        consumerList: "consumers"
    };

    static SPINNER_SIZES = {
        loadingPage: 100,
        hierarchyTab: 12
    };

    static STAGES = {
        alpha: "alpha",
        gamma: "gamma",
        prod: "prod"
    }

    static ATTRIBUTES_TYPES = {
        boolean: "Boolean",
        integer: "Integer",
        string: "String"
    }

    static COLUMNS = {
        A_B_DEGREE: "A-B Degree",
        A_END_CROSS: "A End Cross",
        A_END_DARK_FIBER_HO: "A End Dark Fiber H-off",
        A_END_FABRIC_INTERFACE: "A End Fabric Interface",
        A_END_INTERFACE: "A End Interface",
        A_END_LINE_PORT: "A End Line Port",
        A_END_TRUNK_PORT: "A End Trunk Port",
        AMAZON_FIBER_PAIR_ID: "Amazon Fiber Pair ID",
        AMAZON_SPAN_ID: "Amazon Span ID",
        B_END_CROSS: "B End Cross",
        B_END_DARK_FIBER_HO: "B End Dark Fiber H-off",
        B_END_FABRIC_INTERFACE: "B End Fabric Interface",
        B_END_INTERFACE: "B End Interface",
        B_END_LINE_PORT: "B End Line Port",
        B_END_TRUNK_PORT: "B End Trunk Port",
        CAPACITY: "Capacity",
        CHANNEL_CAPACITY_GBPS: "Channel Capacity Gbps",
        CLIENT_PATH_NAME: "Client Path Name",
        CM_OR_TT_URL: "Deployment MCM/TT URL Link",
        END_A_NSM_REGION: "End A NSM Region",
        END_Z_NSM_REGION: "End Z NSM Region",
        FABRIC: "Fabric",
        FIBER_ID: "Fiber ID",
        FIBER_SERVICE_ID: "Fiber Service ID",
        FIBER_TRAIL: "Fiber Trail",
        LINK_TYPE: "Link Type",
        MAINTENANCE_GROUP: "Maintenance Group",
        NAME: "Name",
        OPTICAL_DISTANCE: "Optical Distance KM",
        OTS_ID: "OTS ID",
        SOL_LOSS_1550_NM: "SOL Loss 1550 NM (dB)",
        STYX_SPAN_NAME: "Styx Span Name",
        TYPE: "Type",
        VENDOR_NAME: "Vendor Name",
        VENDOR_URL: "Vendor Url",
        WAVELENGTH: "Wavelength",
        A_END_DC: "A End DC",
        Z_END_DC: "Z End DC"
    };

    static ATTRIBUTES = {
        aEndPort: "aEndPort",
        bEndPort: "bEndPort",
        aEndClientPort: "aEndClientPort",
        amazon_fibre_pair_id: "amazon_fibre_pair_id",
        amazon_span_id: "amazon_span_id",
        bEndClientPort: "bEndClientPort",
        endId: "endId",
        lifecycleState: "lifecycleState",
        attributesToDisplay: "attributesToDisplay",
        encryptionCapability: "encryptionCapability",
        encryptionIntent: "encryptionIntent",
        legacyCircuitDbId: "legacy_circuitdb_id",
        linkType: "link_type",
        fibre_vendor: "fibre_vendor",
        a_end_dark_fibre_handoff: "a_end_dark_fibre_handoff",
        b_end_dark_fibre_handoff: "b_end_dark_fibre_handoff",
        fibre_id: "fibre_id",
        fibre_service_id: "fibre_service_id",
        maintenance_group: "maintenance_group",
        sol_loss_1550_nm: "sol_loss_1550_nm",
        optical_distance_km: "optical_distance_km",
        vendor_url: "vendor_url",
        cm_or_tt_url: "cm_or_tt_url",
        fibre_trail: "fibre_trail",
        a_b_degree: "a_b_degree",
        type: "type",
        wavelength: "wavelength",
        channel_capacity_gbps: "channel_capacity_gbps",
        client_path_capacity_Gbps: "client_path_capacity_Gbps",
        name: "name",
        end_a_nsm_region: "end_a_nsm_region",
        end_z_nsm_region: "end_z_nsm_region",
        fabric: "fabric",
        a_end_cross_connection: "a_end_cross_connection",
        b_end_cross_connection: "b_end_cross_connection",
        client_path_name: "client_path_name",
        capacity: "capacity",
        a_end_po: "a_end_po",
        b_end_po: "b_end_po",
        intended_a_end_fabric_port: "intended_a_end_fabric_port",
        intended_b_end_fabric_port: "intended_b_end_fabric_port",
        a_end_client_port: "a_end_client_port",
        b_end_client_port: "b_end_client_port",
        state: "state",
        validation_state: "validation_state",
        consumers: "consumers",
        user_provided_id: "user_provided_id",
        och_trail: "och_trail",
        ots_trail: "ots_trail",
        end_a_nsm_name: "end_a_nsm_name",
        end_a_nsm_uuid: "end_a_nsm_uuid",
        end_z_nsm_name: "end_z_nsm_name",
        end_z_nsm_uuid: "end_z_nsm_uuid",
        a_end_dc: "a_end_dc",
        b_end_dc: "b_end_dc",
        z_end_dc: "z_end_dc",
        attributes: "attributes",
        consuming_links: "consuming_links",
        styx_span_name: "styx_span_name",
        styx_a_site_code: "styx_a_site_code",
        styx_a_room_id: "styx_a_room_id",
        styx_a_rack_id: "styx_a_rack_id",
        styx_a_panel_id: "styx_a_panel_id",
        styx_a_panel_type: "styx_a_panel_type",
        styx_a_port_id: "styx_a_port_id",
        styx_z_site_code: "styx_z_site_code",
        styx_z_room_id: "styx_z_room_id",
        styx_z_rack_id: "styx_z_rack_id",
        styx_z_panel_id: "styx_z_panel_id",
        styx_z_panel_type: "styx_z_panel_type",
        styx_z_port_id: "styx_z_port_id",
        native_link: "native_link"
    }

    // Paddings for LinkService
    static PADDING_SIZES = {
        BOX_PAGE_PADDING: "xxl",
        SPACE_BETWEEN_CONTAINER_PADDING: "l",
        SPACE_BETWEEN_SECTIONS: "m",
        SPACE_BETWEEN_STAGES: "s",
        SPACE_BETWEEN_BUTTON_PADDING: "s",
        SPACE_BETWEEN_BUTTON_PADDING_XS: "xs",
        SPACE_BETWEEN_LINK_PADDING: "xxxs"
    };

    static FLASHBAR_TYPES = {
        success: "success",
        error: "error",
        info: "info",
        warning: "warning"
    };

    static COMPONENT_CONSTANTS = {
        BOX_PAGE_PADDING: "xxl",
        SPACE_BETWEEN_CONTAINER_PADDING: "l",
        SPACE_BETWEEN_STAGES: "s",
        SPACE_BETWEEN_BUTTON_PADDING: "s",
        SPACE_BETWEEN_BUTTON_PADDING_XS: "xs",
        SPACE_BETWEEN_LINK_PADDING: "xxxs"
    };

    static ICON_TEXT_TYPES = {
        success: "success",
        error: "error",
        secondaryText: "secondaryText"
    }

    static LINK_TYPES = {
        passiveToPassive: "Passive To Passive",
        muxToMux: "Mux To Mux",
        omsToOms: "Oms To Oms",
        powerMuxToPowerMux: "Powermux To Powermux",
        trunkToTrunk: "Trunk To Trunk",
        oduToOdu: "Odu To Odu",
        routerToDWDM: "Router To DWDM",
        routerToRouter: "Router To Router",
        clientToClient: "Client To Client",
        encryptionToEncryption: "Encryption To Encryption",
        encryptionToDWDM: "Encryption To DWDM",
        routerToEncryption: "Router To Encryption",
        lightInterfaceUnit: "Light Interface Unit",
        intraDcRouterToIntraDcRouter: "IntraDC Router To IntraDC Router"
    }

    static LINK_TYPES_IN_ATTRIBUTES = {
        routerToRouter: "RouterToRouter",
        routerToDWDM: "RouterToDWDM"
    }

    static LINK_DETAIL_FIELDS_MAP = {
        aEndPort: "aEndPort",
        bEndPort: "bEndPort",
        lifecycleState: "lifecycleState",
        readableLinkType: "readableLinkType",
        instanceVersion: "instanceVersion",
        typeVersion: "typeVersion",
        encryptionCapability: "encryptionCapability",
        encryptionIntent: "encryptionIntent",
        appliedEncryption: "appliedEncryption"
    }

    static HEADER_SUFFIX = {
        [Constants.LINK_TYPES.routerToRouter]: "(Client Path Section)"
    }

    static CONTAINER_TYPES = {
        protectionContainer: "Protection Container",
        parallelContainer: "Parallel Container"
    }

    static FILE_TYPES = {
        cutsheet: "CUTSHEET"
    }

    static CUTSHEET_TYPES = {
        cph: "CPH",
        och: "OCH",
        ots: "OTS",
        // Fiber cutsheets are disabled as a part of LinkServices integrating with Styx to ingest all new OSP
        // fiber data automatically. They will only be displayed for NEST team members
        // fiber: "FIBER",
        c2c: "C2C"
    };

    static FIBER_KEY = "FIBER";
    static FIBER_AUDIT_KEY = "FIBER_AUDIT"

    static CUTSHEET_TYPE_LABELS = {
        [Constants.CUTSHEET_TYPES.cph]: "CPH Layer",
        [Constants.CUTSHEET_TYPES.och]: "OCH Layer",
        [Constants.CUTSHEET_TYPES.ots]: "OTS Layer",
        // Fiber cutsheets are disabled as a part of LinkServices integrating with Styx to ingest all new OSP
        // fiber data automatically. They will only be displayed for NEST team members
        [Constants.FIBER_KEY]: "Fiber Layer",
        [Constants.FIBER_AUDIT_KEY]: "Fiber Audit Layer",
        [Constants.CUTSHEET_TYPES.c2c]: "Client to Client Layer"
    };

    static MAX_CUTSHEET_SIZE = 50000000;

    static MAX_CUTSHEET_FILE_FAILURE_REASON_LENGTH = 400;

    static VALID_CUTSHEET_CONTENT_TYPES = [
        "text/csv"
    ];

    static NO_BREAK_SPACE_UNICODE = `\u00A0`;

    static POLARIS_UTILS_OPTIONS = {
        VALUE_KEY: "value",
        LABEL_KEY: "label"
    }

    static FLASHBAR_STRINGS = {
        flashbarSuccessText: "Request Successful!",
        flashbarMidwayError: "Midway session expired, please reload the page in the browser. Remember to save your " +
            "inputted data!",
        networkError: "NetworkError when attempting to fetch resource."
    };

    // From https://tiny.amazon.com/g4hxs1b6
    static LIFECYCLE_STATES = {
        allocated: "ALLOCATED",
        available: "AVAILABLE",
        decommissioned: "DECOMMISSIONED",
        deleted: "DELETED",
        maintenance: "MAINTENANCE",
        operational: "OPERATIONAL",
        planning: "PLANNED",
        reserved: "RESERVED",
        unused: "UNUSED",
        validated: "VALIDATED"
    }

    static ENCRYPTION_CAPABILITIES = {
        isEncryptionCapable: "IS_ENCRYPTION_CAPABLE",
        notSet: "NOT_SET"
    }

    static ENCRYPTION_INTENT_OPTIONS = {
        doNotEncryption: "DO_NOT_ENCRYPT",
        encrypt: "ENCRYPT"
    }

    static UUID_REGEX = /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/;

    static DATE_TIME_FORMAT = "YYYY/MM/DD HH:mm:ss.SSS Z";

    static READABLE_LINK_TYPE_MAP = {
        [Constants.STAGES.alpha]: {
            "amzn1.network.link.type.leaflinktype": Constants.LINK_TYPES.routerToRouter,
            "amzn1.network.link.type.9e7903ed-9c94-4e9b-ab28-ff598e7bc5fe": Constants.LINK_TYPES.routerToDWDM,
            // Trunk to Trunk ID
            "amzn1.network.link.type.b3d381fd-c245-43aa-b894-5a11d34c0fba": Constants.LINK_TYPES.trunkToTrunk,
            // Backfill trunk to trunk ID
            "amzn1.network.link.type.b65c46c9-1cb4-4b70-ab1f-88600a6552e7": Constants.LINK_TYPES.trunkToTrunk,
            "amzn1.network.link.type.9602dd4a-ddd3-407d-b84c-01c981509162": Constants.LINK_TYPES.clientToClient,
            // Mux to Mux ID
            "amzn1.network.link.type.f5921a77-0760-4a3f-bddb-b6cbc5b6cb56": Constants.LINK_TYPES.muxToMux,
            // Backfill Mux to Mux ID
            "amzn1.network.link.type.6aed8719-15bc-4543-bbac-e599490f4e08": Constants.LINK_TYPES.muxToMux,
            "amzn1.network.link.type.ea4a5f06-6bb2-41c3-a9fd-2edc1853efd7": Constants.LINK_TYPES.oduToOdu,
            "amzn1.network.link.type.ca460e60-837b-48f8-b18b-c27cf94ee97f": Constants.LINK_TYPES.omsToOms,
            "amzn1.network.link.type.d196da10-8bba-44ff-b949-2db0156f08a5": Constants.LINK_TYPES.passiveToPassive,
            "amzn1.network.link.type.93a3f0a8-1587-456e-acf0-a6fe133ddbd6": Constants.LINK_TYPES.powerMuxToPowerMux,
            "amzn1.network.link.type.ed81b05e-a926-4205-802a-e769b9dabe28": Constants.CONTAINER_TYPES.parallelContainer,
            "amzn1.network.link.type.fa1b0c93-d39c-464a-9664-987c4123dfb7":
            Constants.CONTAINER_TYPES.protectionContainer,
            "amzn1.network.link.type.0c1a0a06-b07d-4558-9e4f-2645f788fc15": Constants.LINK_TYPES.encryptionToEncryption,
            "amzn1.network.link.type.e617dfe0-5cb3-11ee-8c99-0242ac120002": Constants.LINK_TYPES.lightInterfaceUnit,
            "amzn1.network.link.type.6f577b32-d8da-4b05-b662-629f79916e14":
                Constants.LINK_TYPES.intraDcRouterToIntraDcRouter
        },
        [Constants.STAGES.gamma]: {
            "amzn1.network.link.type.85b3de38-f3a7-4f30-87fe-bf13a63d43a8": Constants.LINK_TYPES.routerToDWDM,
            "amzn1.network.link.type.leaflinktype": Constants.LINK_TYPES.routerToRouter,
            "amzn1.network.link.type.27670d88-38c7-4b85-9774-920dc33a6bb5": Constants.LINK_TYPES.routerToRouter,
            // Trunk to Trunk ID
            "amzn1.network.link.type.a8f6ad0e-a719-4721-8c97-b23d9e2b8111": Constants.LINK_TYPES.trunkToTrunk,
            // Backfill trunk to trunk id
            "amzn1.network.link.type.c0607d9a-e72e-439f-b760-c1f4d638c1b7": Constants.LINK_TYPES.trunkToTrunk,
            "amzn1.network.link.type.9d958a2a-fe84-4449-994e-fe6f0316c3d6": Constants.LINK_TYPES.clientToClient,
            // Mux to Mux ID
            "amzn1.network.link.type.8156d623-74d8-4d8b-9e62-583f157e99d5": Constants.LINK_TYPES.muxToMux,
            // Backfill Mux to Mux ID
            "amzn1.network.link.type.d9b433f4-8df5-4317-9313-eb6f2b61ae65": Constants.LINK_TYPES.muxToMux,
            "amzn1.network.link.type.4282eb54-f06e-441e-b155-aa419ea06c5b": Constants.LINK_TYPES.oduToOdu,
            "amzn1.network.link.type.1a9b71c0-8dfa-4ad2-a2f5-fd2ec3f53b70": Constants.LINK_TYPES.omsToOms,
            "amzn1.network.link.type.3c317578-dc18-47b6-9a7c-725dcf45c399": Constants.LINK_TYPES.passiveToPassive,
            "amzn1.network.link.type.34bdedd5-5c93-48a9-bb6b-292ccb2b7443": Constants.LINK_TYPES.powerMuxToPowerMux,
            "amzn1.network.link.type.0e1c76ac-b6b2-44d8-b67c-dc00d9d7c699": Constants.CONTAINER_TYPES.parallelContainer,
            "amzn1.network.link.type.35cf59a1-4ff3-4488-9ad2-27c5f1a3258d":
            Constants.CONTAINER_TYPES.protectionContainer,
            "amzn1.network.link.type.fa075b0e-bbaf-461a-8c00-aba238c41a4a": Constants.LINK_TYPES.encryptionToEncryption,
            "amzn1.network.link.type.40b62650-7610-4815-9686-5d6b747e4305": Constants.LINK_TYPES.routerToEncryption,
            "amzn1.network.link.type.0646fe11-8d89-422b-9094-bec14e7b8493": Constants.LINK_TYPES.encryptionToDWDM,
            "amzn1.network.link.type.e59e3aac-5e00-11ee-8c99-0242ac120002": Constants.LINK_TYPES.lightInterfaceUnit,
            "amzn1.network.link.type.21642bef-aa2d-47d3-bd1a-e41043502086":
                Constants.LINK_TYPES.intraDcRouterToIntraDcRouter
        },
        [Constants.STAGES.prod]: {
            "amzn1.network.link.type.f624dc55-fb37-452f-a093-5bd8fc4c09d7": Constants.LINK_TYPES.routerToDWDM,
            "amzn1.network.link.type.leaflinktype": Constants.LINK_TYPES.routerToRouter,
            "amzn1.network.link.type.1677aeed-b182-49ba-989c-c26aa55d1de2": Constants.LINK_TYPES.routerToRouter,
            // Trunk to Trunk ID
            "amzn1.network.link.type.c87e8297-15cc-4114-9d05-fcf0bcf6450d": Constants.LINK_TYPES.trunkToTrunk,
            // Backfill trunk to trunk ID
            "amzn1.network.link.type.7752318c-5f91-467c-84bf-adf924585976": Constants.LINK_TYPES.trunkToTrunk,
            "amzn1.network.link.type.f61d6bcf-e2e3-46aa-b9ee-61d6f5f162d0": Constants.LINK_TYPES.clientToClient,
            // Mux to Mux ID
            "amzn1.network.link.type.a1fc9b8e-fb28-40f0-b4ab-1816c05bfaa2": Constants.LINK_TYPES.muxToMux,
            // Backfill mux to mux ID
            "amzn1.network.link.type.7b183f63-1bb6-4d19-87cf-49efbf928947": Constants.LINK_TYPES.muxToMux,
            "amzn1.network.link.type.e60da63d-de82-487c-bacc-84f17271f00a": Constants.LINK_TYPES.oduToOdu,
            "amzn1.network.link.type.23c28d90-fb3a-4d53-9d5b-72aae3a0393e": Constants.LINK_TYPES.omsToOms,
            "amzn1.network.link.type.d62b3b30-1730-41a5-9e90-36f00e5f0eaa": Constants.LINK_TYPES.passiveToPassive,
            "amzn1.network.link.type.a2640b69-f769-4bd6-9562-615d44e5c260": Constants.LINK_TYPES.powerMuxToPowerMux,
            "amzn1.network.link.type.ecd84d73-a0a3-47c2-9bde-5e8de080ba0d": Constants.CONTAINER_TYPES.parallelContainer,
            "amzn1.network.link.type.24515b48-a902-4879-bb47-063527429d08":
            Constants.CONTAINER_TYPES.protectionContainer,
            "amzn1.network.link.type.cc6329c4-1ae5-4831-86bd-ea9853e6f65c": Constants.LINK_TYPES.encryptionToEncryption,
            "amzn1.network.link.type.40e4b45a-e504-46c0-b1c2-28442c83cc80": Constants.LINK_TYPES.routerToEncryption,
            "amzn1.network.link.type.859d64ce-09d5-4652-8050-b2706f423901": Constants.LINK_TYPES.encryptionToDWDM,
            "amzn1.network.link.type.f35e7404-5e00-11ee-8c99-0242ac120002": Constants.LINK_TYPES.lightInterfaceUnit,
            "amzn1.network.link.type.5560a597-a383-4a36-8e96-7ee375178305":
                Constants.LINK_TYPES.intraDcRouterToIntraDcRouter
        }
    }

    static ERROR_STRINGS = {
        FAILED_TO_FETCH: "Failed to fetch",
        UI_PROCESSING_TIME_EXCEEDED: "UI_PROCESSING_TIME_EXCEEDED"
    }

    static MAX_UI_PROCESSING_SECONDS = 20;

    static DOWNLOADABLE_COLUMNS = {
        fibre_id: "fibre_id",
        state: "state",
        cm_or_tt_url: "cm_or_tt_url",
        a_end_dark_fibre_handoff: "a_end_dark_fibre_handoff",
        b_end_dark_fibre_handoff: "b_end_dark_fibre_handoff",
        a_end_cross_connection: "a_end_cross_connection",
        b_end_cross_connection: "b_end_cross_connection",
        fibre_vendor: "fibre_vendor",
        fibre_service_id: "fibre_service_id",
        maintenance_group: "maintenance_group",
        sol_loss_1550_nm: "sol_loss_1550_nm",
        optical_distance_km: "optical_distance_km",
        vendor_url: "vendor_url",
        ots_id: "ots_id",
        a_b_degree: "a_b_degree",
        a_end_line_port: "a_end_line_port",
        b_end_line_port: "b_end_line_port",
        fibre_trail: "fibre_trail",
        och_id: "och_id",
        och_type: "och_type",
        a_end_trunk_port: "a_end_trunk_port",
        b_end_trunk_port: "b_end_trunk_port",
        wavelength: "wavelength",
        channel_capacity_gbps: "channel_capacity_gbps",
        name: "name",
        provider: "provider",
        ots_trail: "ots_trail",
        client_path_name: "client_path_name",
        fabric: "fabric",
        intended_a_end_fabric_port: "intended_a_end_fabric_port",
        a_end_po: "a_end_po",
        a_end_client_port: "a_end_client_port",
        intended_b_end_fabric_port: "intended_b_end_fabric_port",
        b_end_po: "b_end_po",
        b_end_client_port: "b_end_client_port",
        a_end_dc: "a_end_dc",
        b_end_dc: "b_end_dc"
    };

    static CONSUMPTION_ACTIONS = {
        ADD: "add",
        REMOVE: "remove"
    };

    static POSIX_GROUPS = {
        NEST: "nest"
    };

    static CUTSHEET_TEMPLATE_ROWS = {
        [Constants.CUTSHEET_TYPES.cph]: [
            ["state", "cm_or_tt_url", "fabric", "client_path_capacity_Gbps", "a_end_fabric_port", "a_end_po",
                "a_end_client_port", "a_end_trunk_port", "b_end_fabric_port", "b_end_po",
                "b_end_client_port", "b_end_trunk_port", "is_briggs", "och_trail", "fiber_path", "a_optic_type",
                "a_connector_type", "a_fiber_type", "b_fiber_type", "b_connector_type", "b_optic_type",
                "a_client_dwdm_optic_type", "b_client_dwdm_optic_type"],

            ["planning", "https://mcm.amazon.com/cms/MCM-77408063", "BR", "100", "atl50-br-bfb-f1-b3-t1-r1_et-0/0/1", "",
                "atl50-wdm-pp6-s2_Port-3/3", "atl056-wdm-l022-s11_PTP-1", "iad79-br-bfb-f1-b6-t1-r1_et-0/0/19", "",
                "iad79-wdm-pp488-s2_Port-1/3", "iad006-wdm-l022-s11_PTP-1", "", "atl056-iad006_l022_1528.77", "Path 1",
                "LR4", "LC", "SM", "SM", "LC", "LR4", "DR1", "DR1"],
            ["planning", "https://mcm.amazon.com/cms/MCM-44659013", "BR", "100", "atl50-br-bfb-f1-b3-t1-r1_jrp1", "",
                "atl50-br-bfb-f1-b3-t1-r1_jrp1", "atl50-br-bfb-f1-b3-t1-r1_jrp1", "iad79-br-bfb-f1-b6-t1-r1_jrp1", "",
                "iad79-br-bfb-f1-b6-t1-r1_jrp1", "iad79-br-bfb-f1-b6-t1-r1_jrp1", "TRUE", "atl056-iad006_l022_1529.07",
                "Path 2", "LR4", "LC", "SM", "SM", "LC", "LR4", "DR1", "DR1"]
        ],
        [Constants.CUTSHEET_TYPES.c2c]: [
            ["client_path_name", "a_end_trunk_port", "a_end_client_port", "b_end_trunk_port", "b_end_client_port",
                "a_optic_type", "b_optic_type"],
            ["NP_iad6-6-np-cor-r101_xe-0/0/94_iad62-62-np-cor-r101_xe-0/0/8", "iad12-wdm-pp135-s38_PTP-1",
                "iad12-wdm-pp135-s38_Port-3.1", "iad12-wdm-pp98-s2_CH-1-4-N", "iad12-wdm-pp98-s2_CH-1-4-C2",
                "DR1", "DR1"]
        ],
        [Constants.CUTSHEET_TYPES.och]: [
            ["och_id", "och_type", "a_end_trunk_port", "b_end_trunk_port", "wavelength", "channel_capacity_Gbps",
                "name", "provider", "a_end_cross_connection", "b_end_cross_connection",
                "cm_or_tt_url", "state", "ots_trail", "a_mux_dwdm_port", "b_mux_dwdm_port"],
            ["atl056-iad006_l022_1528.77", "owned", "atl056-wdm-l022-s11_PTP-1", "iad006-wdm-l022-s11_PTP-1",
                "1528.77", "150", "atl056-wdm-l022-s11_1_a_150_b_iad006-wdm-l022-s11_1", "", "", "",
                "https://mcm.amazon.com/cms/MCM-38204851", "planning", "\"ahn400-gsp400_l022,atl056-ahn400_l022,clt400-gso400_l022\""]
        ],
        [Constants.CUTSHEET_TYPES.ots]: [
            ["ots_id", "state", "cm_or_tt_url", "a_end_dc", "b_end_dc", "a_b_degree",
                "a_end_line_port", "b_end_line_port", "fibre_trail", "a_ops_dwdm_port", "b_ops_dwdm_port",
                "a_end_line_port_2", "b_end_line_port_2", "a_rfts_filter", "b_rfts_filter", "a_rfts_in_port_1",
                "b_rfts_in_port_1", "a_rfts_out_port_1", "b_rfts_out_port_1", "a_rfts_in_port_2", "b_rfts_in_port_2",
                "a_rfts_out_port_2", "b_rfts_out_port_2", "a_pluggable", "b_pluggable",
                "a_connector_type", "b_connector_type", "comment"],
            ["ahn400-gsp400_l022", "in_service", "https://mcm.amazon.com/cms/MCM-75319904", "ahn400", "gsp400", "a-b",
                "ahn400-wdm-l022-i01_RAMAN-1-6-8", "gsp400-wdm-l022-i01_RAMAN-1-2-8", "ahn400-gsp400_p1",
                "OPS3 COM IN (17) & OUT(18)", "OPS3 COM IN (17) & OUT(18)", "ahn400-wdm-l022-i01_RAMAN-1-6-9",
                "gsp400-wdm-l022-i01_RAMAN-1-6-7", "ahn400_l022-wdm-OCTPS", "gsp400_l022-wdm-OCTPS", "DWDM Rx",
                "DWDM Rx", "Line Tx", "Line Tx", "DWDM Tx", "DWDM Tx", "Line Rx", "Line Rx", "LC/SC", "LC/SC",
                "LC/UPC", "LC/UPC", "comment"]
        ]
    }
}
